@defaultPrimaryColor: #235bdb;

:root {
  --color-primary: @defaultPrimaryColor;
  --color-primary-lighter: @defaultPrimaryColor;
  --contain-height: calc(100vh - 90px);
}

@colorPrimary: var(--color-primary);
@colorPrimaryLighter: var(--color-primary-lighter);
@colorPrimaryBg: #f0f6ff;

@white: #fff;
@light-white: #fcfcfc;
@light-grey: #eeeeee;
@light-border-color: #e9ecf0; //ececec
@light-hover-blue: #e9ecf0;
@border-color: #ccc;

@table-header-bg: #f7f7f7;

//文字用色
@text-dark-color: #333333;
@text-middle-color: #666666;
@text-light-color: #898989;
@title-color: #000000;
@sub-title-color: #333333;
@content-color: #4d4d4d;
@tips-color: #8d8d8d;
@tips-color-light: #919da3;
