html:root {
  --color-primary: #235bdb;
  --color-primary-lighter: #235bdb;
  --contain-height: calc(100vh - 90px);
}
body div.ant-tooltip .ant-tooltip-inner {
  color: #333333;
  font-weight: 400;
  background: #fff;
  border-radius: 4px;
  white-space: normal;
  font-size: 14px;
}
body div.ant-tooltip .ant-tooltip-arrow::before,
body div.ant-tooltip .ant-tooltip-arrow::after {
  background-color: #fff;
}
body div.ant-tooltip .ant-tooltip.ant-menu-inline-collapsed-tooltip a {
  color: #333333;
  font-size: 14px;
}
body .ant-alert-info .ant-alert-icon {
  color: var(--color-primary-lighter);
}
body .ant-table-wrapper .ant-table-thead > tr > th {
  border-bottom: 0;
  background-color: #f7f7f7;
  color: #333333;
}
body .ant-table-wrapper .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}
body .ant-table-wrapper .ant-table-content {
  color: #333;
}
body .ant-table-wrapper .ant-table-pagination.ant-pagination {
  position: relative;
}
body .ant-table-wrapper .ant-table-pagination.ant-pagination .ant-pagination-total-text {
  position: absolute;
  left: 0;
}
