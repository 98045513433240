@import './tailwind.css';
@import url('./var.less');

* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

body {
  overflow-x: hidden;
}

//链接样式
a,
.ant-btn-link:not(:disabled),
a.ant-typography {
  color: @colorPrimary;

  &:hover {
    color: @colorPrimaryLighter;
  }
}

//滚动条样式
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.ant-table-wrapper .ant-table {
  scrollbar-color: unset;
}

.page-container {
  height: var(--contain-height);
  display: flex;
  overflow-y: auto;
}

.radio-button-no-border {
  & > label {
    border: 0 !important;
    &::after {
      display: none !important;
    }
    &::before {
      display: none !important;
    }

    span {
      border: 0 !important;
      text-align: center;
      min-width: 45px;
      display: inline-block;
    }
  }

  .ant-radio-button-wrapper {
    padding-inline: 0;
  }

  :global(.ant-radio-button-wrapper-checked) {
    font-weight: 600;
  }
}
.page-left-nav {
  margin-inline-start: 1px;
  min-width: 200px;
  background-color: @white;
  overflow-y: auto;
  overflow-x: hidden;

  & > li.ant-menu-item {
    margin-inline: 0;
    width: 100%;
    margin-block: 0;

    & > span > div {
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & > li.ant-menu-item-selected {
    position: relative;
    border-radius: 0;

    &::before {
      content: ' ';
      border-left: 2px solid @colorPrimary;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }
}

//确认框 高亮关键词
.confirm-word {
  color: @colorPrimary;
  margin: 0 3px;
}

.action-icon {
  font-size: 14px;
}

.action-btn {
  padding: 0;
}

.ellipsis-text {
  /* 防止文本换行 */
  white-space: nowrap;
  /* 当文本溢出时隐藏超出部分 */
  overflow: hidden;
  /* 在文本溢出的地方显示省略号 */
  text-overflow: ellipsis;
}

.ellipsis-3-line {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 限制显示的行数 */
  -webkit-box-orient: vertical; /* 设置盒子的垂直方向布局 */
  overflow: hidden;
  text-overflow: ellipsis;
}
